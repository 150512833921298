import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/article.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Editor's Note: This is a promotional message that is republished here in public interest.`}</strong></p>
    <p>{`The Rural Sensitization Program is an experiential learning process for medical students, postgraduates and young doctors to expose them to rural perspectives. You will talk to health workers, farmers, craftsman, villagers to understand their stories and experiences. Through the observations, individual and group reflections and discussions, we hope that we can draw out together a broader map of the country’s health problems and ways of addressing them.`}</p>
    <p>{`Duration - 3 days`}</p>
    <p>{`When - Batches in March, May, and September 2020`}</p>
    <p>{`Number per batch - 30`}</p>
    <p>{`Priority - First come first serve - Based on how soon you apply`}</p>
    <p>{`Venue - Sittilingi, Dharmapuri district or Gudalur, Nilgiris (Both in Tamil Nadu)`}</p>
    <p>{`For more details please contact - Sangeetha `}<a parentName="p" {...{
        "href": "tel:+918870193925"
      }}>{`8870193925`}</a>{`, or `}<a parentName="p" {...{
        "href": "mailto:rsptribalhealth@gmail.com"
      }}>{`rsptribalhealth@gmail.com`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://docs.google.com/forms/d/1CPzIjmJ_CdXnqVwUJh96P4clnTGrGuNONqtrLN4MBWo/viewform"
      }}>{`Application Form`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      